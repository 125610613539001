import {
    GET_FACILITY_LIST,
    PAGINATED_GET_FACILITY_LIST,
} from './constants';

const initialData = {
    isFetching: false,
    isFetchingPaginatedResults: false,
    facilityList: null,
};

export default function facilityListReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_FACILITY_LIST.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_FACILITY_LIST.success:
            return {
                ...state,
                isFetching: false,
                facilityList: payload,
            };
        case GET_FACILITY_LIST.failure:
            return {
                ...state,
                isFetching: false,
                facilityList: null,
            };
        case PAGINATED_GET_FACILITY_LIST.request:
            return {
                ...state,
                isFetchingPaginatedResults: true,
            };
        case PAGINATED_GET_FACILITY_LIST.success:
            return {
                ...state,
                isFetchingPaginatedResults: false,
                facilityList: {
                    data: [...state.facilityList.data, ...payload.data],
                },
            };
        case PAGINATED_GET_FACILITY_LIST.failure:
            return {
                ...state,
                isFetchingPaginatedResults: false,
            };
        default:
            return state;
    }
}
