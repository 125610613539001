import { Auth, Cache } from 'aws-amplify';

export default async function () {
    let session = Cache.getItem('session');
    if (session) {
        const token = session.idToken.jwtToken;
        const jwtToken = parseJwt(token);
        if (isExpired(jwtToken)) {
            session = await Auth.currentSession();
            Cache.setItem('session', session);
        }
    } else {
        session = await Auth.currentSession();
        Cache.setItem('session', session);
    }
    return session;
}

function isExpired(jwtToken) {
    if (!jwtToken) {
        return true;
    }
    const ts = new Date().getTime();
    const delta = 10 * 60 * 1000; // 10 minutes
    return (jwtToken.exp * 1000) <= ts + delta;
}

function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}

