import { 
    GET_TIME_ENTRY,
    ADD_TIME_ENTRY,
    UPDATE_TIME_ENTRY,
    SET_TIME_ENTRY_STAFF_VALUE,
    SET_TIME_ENTRY_FIELD_VALUE,
    RESET_TIME_ENTRY_FORM
 } from './constants';

const initialData = {
    isFetching: false,
    timeEntry: {
        id: '',
        duration: 0,
        Facility: {
            id: '',
        },
        Staff: {
            id: '',
        },
        floor_units: {
            id: '',
        },
        start_time: "",
        end_time: "",
        shift_date: "",
        note: '',
        notes: [],
        guaranteed: false,
        time_and_a_half: false,
        bonus: false,
        travel_mileage: 0,
        cancelled_on_arrival: false,
        staff_signature_file: "",
        facility_signature_file: "",
        facility_printed_name: "",
    }
}

export default function timeEntryReducer(state = initialData, {payload, ...action}) {
    switch (action.type) {
        case GET_TIME_ENTRY.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_TIME_ENTRY.success:
            return {
                ...state,
                isFetching: false,
                timeEntry: payload,
            };
        case GET_TIME_ENTRY.failure:
            return {
                ...state,
                isFetching: false,
                timeEntry: null,
            };
        case ADD_TIME_ENTRY.request:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_TIME_ENTRY.success:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_TIME_ENTRY.failure:
            return {
                ...state,
                isFetching: false,
            };
        case UPDATE_TIME_ENTRY.request:
            return {
                ...state,
                isFetching: true,
            };
        case UPDATE_TIME_ENTRY.success:
            return {
                ...state,
                isFetching: false,
            };
        case UPDATE_TIME_ENTRY.failure:
            return {
                ...state,
                isFetching: false,
            };
        case SET_TIME_ENTRY_STAFF_VALUE:
            return {
                ...state,
                staff: payload.value,
                isFetching: false,
            };
        case SET_TIME_ENTRY_FIELD_VALUE:
            return {    
                timeEntry: {
                    ...state.timeEntry,
                    [payload.name]: payload.value,
                    
                },
                isFetching: false,
            };
        case RESET_TIME_ENTRY_FORM: {
            return initialData;
        }
        default:
            return state;        
    }
}
