import {
    GET_FACILITY,
    SET_FACILITY_FIELD_VALUES,
    SET_FACILITY_STAFF_VALUES,
    RESET_FACILITY_FORM,
    UPDATE_FACILITY,
    ADD_FACILITY,
} from './constants';

const initialData = {
    isFetching: false,
    facility: {
        id: '',
        name: '',
        main_phone: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zip: '',
        admin_first_name: '',
        admin_last_name: '',
        admin_phone: '',
        admin_phone_ext: '',
        admin_email_address: '',
        staffing_coordinator_first_name: '',
        staffing_coordinator_last_name: '',
        staffing_coordinator_email_address: '',
        staffing_coordinator_phone_number: '',
        staffing_coordinator_phone_ext: '',
        types: [],
        floors: [],
        users: [],
    },
    staff: [],
};

export default function facilityReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_FACILITY.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_FACILITY.success:
            return {
                ...state,
                isFetching: false,
                facility: payload,
            };
        case GET_FACILITY.failure:
            return {
                ...state,
                isFetching: false,
                facility: null,
            };
        case SET_FACILITY_FIELD_VALUES: {
            return {
                ...state,
                facility: {
                    ...state.facility,
                    [payload.name]: payload.value,
                },
            };
        }
        case SET_FACILITY_STAFF_VALUES: {
            return {
                ...state,
                staff: payload.value,
            };
        }
        case UPDATE_FACILITY.request:
            return {
                ...state,
                isFetching: true,
            };
        case UPDATE_FACILITY.success:
            return {
                ...state,
                isFetching: false,
            };
        case UPDATE_FACILITY.failure:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_FACILITY.request:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_FACILITY.success:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_FACILITY.failure:
            return {
                ...state,
                isFetching: false,
            };
        case RESET_FACILITY_FORM: {
            return initialData;
        }
        default:
            return state;
    }
}
