import {
    GET_DOCUMENT,
    SET_DOCUMENT_FIELD_VALUES,
    RESET_DOCUMENT_FORM,
    UPDATE_DOCUMENT,
    ADD_DOCUMENT,
    SET_DOCUMENT_FIELDS_VALUES,
    CLEAR_DOCUMENT_DATA,
} from './constants';

const initialData = {
    isFetching: false,
    document: {
      id: '',
      name: '',
      expiration_date: '',
      content: '',
      license: {},
      credential: {},
      entity: {},
      owner_id: '',
      uploaded_by: '',
      owner_type: ''
    },
};

export default function documentReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_DOCUMENT.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_DOCUMENT.success:
            return {
                ...state,
                isFetching: false,
                document: payload,
            };
        case GET_DOCUMENT.failure:
            return {
                ...state,
                isFetching: false,
                document: null,
            };
        case SET_DOCUMENT_FIELD_VALUES: {
            return {
                ...state,
                document: {
                    ...state.document,
                    [payload.name]: payload.value,
                },
            };
        }
        case SET_DOCUMENT_FIELDS_VALUES:
            return {
                ...state,
                document: {
                    ...state.document,
                    ...payload,
                },
            };
        case UPDATE_DOCUMENT.request:
            return {
                ...state,
                isFetching: true,
            };
        case UPDATE_DOCUMENT.success:
            return {
                ...state,
                isFetching: false,
            };
        case UPDATE_DOCUMENT.failure:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_DOCUMENT.request:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_DOCUMENT.success:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_DOCUMENT.failure:
            return {
                ...state,
                isFetching: false,
            };
        case RESET_DOCUMENT_FORM:
            return {
                isFetching: false,
                document: {
                    ...initialData.document,
                    facility: state.document.facility,
                },
            };
        case CLEAR_DOCUMENT_DATA:
            return {
                agencies: [],
                isFetching: false,
                document: initialData.document,
            };
        default:
            return state;
    }
}
