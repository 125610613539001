import {
    GET_USER,
    SET_FIELD_VALUES,
    RESET_FORM,
    UPDATE_AGENCY_USER,
    ADD_AGENCY_USER,
} from './constants';

const initialData = {
    isFetching: false,
    agencyUser: {
        id: '',
        first_name: '',
        last_name: '',
        user_name: '',
        email: '',
        roles: [],
        staff: {},
        phone_number: '',
        extension: '',
        home_phone: '',
        home_phone_ext: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zip: '',
        notification: {},
        agency_id: '',
        active: '',
    },
};

export default function agencyUserReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_USER.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_USER.success:
            return {
                ...state,
                isFetching: false,
                agencyUser: payload,
            };
        case GET_USER.failure:
            return {
                ...state,
                isFetching: false,
                agencyUser: null,
            };
        case SET_FIELD_VALUES: {
            return {
                ...state,
                agencyUser: {
                    ...state.agencyUser,
                    [payload.name]: payload.value,
                },
            };
        }
        case UPDATE_AGENCY_USER.request:
            return {
                ...state,
                isFetching: true,
            };
        case UPDATE_AGENCY_USER.success:
            return {
                ...state,
                isFetching: false,
            };
        case UPDATE_AGENCY_USER.failure:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_AGENCY_USER.request:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_AGENCY_USER.success:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_AGENCY_USER.failure:
            return {
                ...state,
                isFetching: false,
            };
        case RESET_FORM: {
            return initialData;
        }
        default:
            return state;
    }
}
