import {
    GET_USER,
    SET_FIELD_VALUES,
    RESET_FORM,
    UPDATE_FACILITY_USER,
    ADD_FACILITY_USER,
} from './constants';

const initialData = {
    isFetching: false,
    facilityUser: {
        id: '',
        first_name: '',
        last_name: '',
        user_name: '',
        email: '',
        roles: [],
        staff: {},
        phone_number: '',
        extension: '',
        home_phone: '',
        home_phone_ext: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zip: '',
        notification: {},
    },
};

export default function facilityUserReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_USER.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_USER.success:
            return {
                ...state,
                isFetching: false,
                facilityUser: payload,
            };
        case GET_USER.failure:
            return {
                ...state,
                isFetching: false,
                facilityUser: null,
            };
        case SET_FIELD_VALUES: {
            return {
                ...state,
                facilityUser: {
                    ...state.facilityUser,
                    [payload.name]: payload.value,
                },
            };
        }
        case UPDATE_FACILITY_USER.request:
            return {
                ...state,
                isFetching: true,
            };
        case UPDATE_FACILITY_USER.success:
            return {
                ...state,
                isFetching: false,
            };
        case UPDATE_FACILITY_USER.failure:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_FACILITY_USER.request:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_FACILITY_USER.success:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_FACILITY_USER.failure:
            return {
                ...state,
                isFetching: false,
            };
        case RESET_FORM: {
            return initialData;
        }
        default:
            return state;
    }
}
