import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import PropTypes from 'prop-types';
import Home from './containers/Home';
import Dashboard from './containers/Dashboard';
import Login from './containers/Login';
import ForgotPassword from './containers/ForgotPassword';
import ResetPassword from './containers/ResetPassword';
import AgencyList from './containers/AgencyList';
import AgencyManagement from './containers/AgencyManagement';
import AgencyDetails from './containers/AgencyDetails';
import AgencyAdminManagement from './containers/AgencyAdminManagement';
import AgencyUserManagement from './containers/AgencyUserManagement';
import FacilityList from './containers/FacilityList';
import FacilityManagement from './containers/FacilityManagement';
import FacilityDetails from './containers/FacilityDetails';
import FacilityAdminManagement from './containers/FacilityAdminManagement';
import FacilityUserManagement from './containers/FacilityUserManagement';
import Settings from './containers/Settings';
import NotFound from './containers/NotFound';
import ProtectedRoute from './ProtectedRoutes';
import StaffList from './containers/StaffList';
import StaffManagement from './containers/StaffManagement';
import StaffDetails from './containers/StaffDetails';
import ShiftList from './containers/ShiftList';
import ShiftManagement from './containers/ShiftManagement';
import StaffDocumentManagement from './containers/StaffDocumentManagement';
import TimeEntryList from './containers/TimeEntryList';
import TimeEntryManagement from './containers/TimeEntryManagement';
import { history } from './store';

const Routes = ({ childProps }) => (
    <ConnectedRouter history={history}>
        <Switch>
            <ProtectedRoute path="/" exact component={Home} childProps={childProps} />
            <ProtectedRoute path="/login" exact component={Login} childProps={childProps} />
            <ProtectedRoute path="/login/:username/:password" component={Login} childProps={childProps} />
            <ProtectedRoute path="/forgot-password" exact component={ForgotPassword} childProps={childProps} />
            <ProtectedRoute path="/dashboard" exact component={Dashboard} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/agencies" exact component={AgencyList} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/agencies/:id" exact component={AgencyManagement} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/agency/:id" exact component={AgencyDetails} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/agency/:id/time_entries" exact component={TimeEntryList} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/agency/:id/time_entries/create" exact component={TimeEntryManagement} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/agency/:id/time_entries/:id" exact component={TimeEntryManagement} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/agency/:agencyId/users/:id/:userId?" exact component={AgencyUserManagement} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute
                path="/agency-admin/:id/:agencyId/:name"
                exact
                component={AgencyAdminManagement}
                displayHeader
                childProps={childProps}
                withAuth
            />
            <ProtectedRoute path="/facilities" exact component={FacilityList} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/facilities/:id" exact component={FacilityManagement} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/facility/:id" exact component={FacilityDetails} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/facility/:id/time_entries" exact component={TimeEntryList} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/facility/:id/time_entries/create" exact component={TimeEntryManagement} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/facility/:id/time_entries/:id" exact component={TimeEntryManagement} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/facility/:facilityId/users/:id/:userId?" exact component={FacilityUserManagement} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute
                path="/facility-admin/:id/:facilityId/:name"
                exact
                component={FacilityAdminManagement}
                displayHeader
                childProps={childProps}
                withAuth
            />
            <ProtectedRoute path="/Staff" exact component={StaffList} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/Staff/:id" exact component={StaffManagement} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/staff-details/:id" exact component={StaffDetails} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/staff-details/:id/documents/:document_id" exact component={StaffDocumentManagement} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/shifts" exact component={ShiftList} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/shifts/:id" exact component={ShiftManagement} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/settings" exact component={Settings} displayHeader childProps={childProps} withAuth />
            <ProtectedRoute path="/reset-password" exact component={ResetPassword} displayHeader childProps={childProps} withAuth />
            <Route path="*" component={NotFound} />
        </Switch>
    </ConnectedRouter>
);

export default Routes;

Routes.propTypes = {
    childProps: PropTypes.object,
};
