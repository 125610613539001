export const WAIT_INTERVAL = 700;

export const PAGE_SIZE = 18;

export const PAGE_HEIGHT_THRESHOLD = 0.7;
export const OVERTIME_THRESHOLD = 40;
export const SHIFT_ROW_MAX_HEIGHT = 200;
export const STAFF_ROW_MAX_HEIGHT = 160;
export const STAFF_ROW_MIN_HEIGHT = 125;
export const SELECT_ALL = 'select-all';
export const DESELECT_ALL = 'deselect-all';
export const USER_STATUS_FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD";
export const TIME_ENTRY_CONFIRMATION = 'I certify that the hours shown above are correct for billable purposes and that the agency representative performed satisfactorily.';

export const ROLES = {
    STAFF: 'staff',
    FACILITY: 'facility_admin',
    AGENCY: 'agency_admin',
    FACILITY_EMPLOYEE: 'facility_employee',
    AGENCY_EMPLOYEE: 'agency_employee',
    SUPER_ADMIN: 'super_admin',
};

export const SETTINGS_TYPE = {
    CREDENTIALS: 'Credentials',
    LICENSE: 'License',
};

export const NOTE_CATEGORIES = {
  BONUS: 'bonus',
  NO_LUNCH: 'no_lunch',
  INACCURATE_PUNCH: 'inaccurate_punch',
  GENERAL: 'general',
  CANCELLATION: 'shift_cancellation',
};

export const PRIVILEGES = {
    CREATE_AGENCY: 'ADD_AGENCY',
    EDIT_AGENCY: 'EDIT_AGENCY',
    ADD_AGENCY_ADMIN: 'ADD_AGENCY_ADMIN',
    EDIT_AGENCY_ADMIN: 'EDIT_AGENCY_ADMIN',
    ADD_AGENCY_USER: 'ADD_AGENCY_USER',
    EDIT_AGENCY_USER: 'EDIT_AGENCY_USER',
    UPLOAD_AGENCY_DOCUMENT: 'UPLOAD_AGENCY_DOCUMENT',
    DELETE_ADMIN_DOCUMENT: 'DELETE_ADMIN_DOCUMENT',
    CREATE_FACILITY: 'ADD_FACILITY',
    EDIT_FACILITY: 'EDIT_FACILITY',
    UPLOAD_FACILITY_DOCUMENT: 'UPLOAD_FACILITY_DOCUMENT',
    ADD_FACILITY_ADMIN: 'ADD_FACILITY_ADMIN',
    EDIT_FACILITY_ADMIN: 'EDIT_FACILITY_ADMIN',
    ADD_FACILITY_USER: 'ADD_FACILITY_USER',
    EDIT_FACILITY_USER: 'EDIT_FACILITY_USER',
    ADD_SHIFT: 'ADD_SHIFT',
    CLAIM_SHIFT: 'CLAIM_SHIFT',
    DELETE_SHIFT: 'DELETE_SHIFT',
    EDIT_SHIFT: 'EDIT_SHIFT',
    ADD_STAFF: 'ADD_STAFF',
    EDIT_STAFF: 'EDIT_STAFF',
    UPLOAD_STAFF_DOCUMENT: 'UPLOAD_STAFF_DOCUMENT',
    DELETE_STAFF_DOCUMENT: 'DELETE_STAFF_DOCUMENT',
    HANDLE_CANCELLATION_REQUESTS: 'HANDLE_CANCELLATION_REQUESTS',
    REQUEST_SHIFT_CANCELLATION: 'REQUEST_SHIFT_CANCELLATION',
    RESEND_STAFF_VERIFICATION_LINK: 'RESEND_STAFF_VERIFICATION_LINK',
    HANDLE_PRESETS: 'HANDLE_PRESETS',
    ADD_SETTINGS: 'ADD_SETTINGS',
};

export const SHIFT_STATUS = {
    AVAILABLE: 'available',
    CANCELLED: 'cancelled',
    SCHEDULED: 'scheduled',
    CANCEL_REQUEST: 'cancellation_request',
    UNCLAIMED: 'unclaimed',
    COMPLETED: 'completed',
    CONTRACTS: 'contracts',
    CLAIMED:   'claimed',
    ASSIGNED:  'assigned',
};

export const STAFF_TABS = {
  ALL: 'all',
  EXPIRING_DOCUMENTS: 'expiring_documents',
  EXPIRED_DOCUMENTS:  'expired_documents',
};

export const DATE_FORMAT = {
    ISO: 'YYYY-MM-DDTHH:mm:ss.sss',
    LOCAL: 'YYYY-MM-DDTHH:mm:ssZ',
    MMDDYYYY: 'MM/DD/YYYY',
    DATETIME: 'MM/DD/YYYY HH:mm:ss',
    YYYYMMDD: 'YYYY-MM-DD',
};

export const PRESET_TYPE = {
    CUSTOM: 'Custom',
};

export const FLOOR_TYPE = {
  NEW_FLOOR: 'New Floor',
};

export const ENTITY = {
    STAFF: 'staff',
    FACILITY: 'facility',
    AGENCY: 'agency',
};

export const TABS = {
    AGENCIES: 'AGENCIES',
    FACILITIES: 'FACILITIES',
    STAFF: 'STAFF',
    SHIFTS: 'SHIFTS',
};

export const ACTION_TYPE = {
    UPDATE: 'Update',
    ADD: 'Add',
};

export const SHIFT_TYPE = {
    SHIFT: "Shift",
    CONTRACT: "Contract",
};

export const SKIP_URLS = ['/login', 'forgot-password'];

export const ROUTE_TABLE = {
    agencies: '/agencies',
    agencyDetails: '/agency',
    agencyAdmin: '/agency-admin',
    facilities: '/facilities',
    facilityDetails: '/facility',
    facilityAdmin: '/facility-admin',
    staff: '/staff',
    staffDetails: '/staff-details',
    dashboard: '/dashboard',
    settings: '/settings',
    shifts: '/shifts',
    resetPassword: '/reset-password',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: (ITEM_HEIGHT * 4.5) + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const CONFIRMATION_MESSAGES = {
    CANCEL_SHIFT: 'Are you sure you want to cancel the shift?',
    CANCEL_GUARANTEED_SHIFT : 'This shift is guaranteed. The agency and staff will still get paid for it, despite cancelling. Are you sure you want to cancel?', 
    AGENCY_SHIFT_CANCELLATION: 'Are you sure you want to cancel this shift for staff?',
    REQUEST_SHIFT_CANCELLATION: 'Are you sure you want to request shift cancellation?',
    APPROVE_SHIFT_CANCELLATION: 'Are you sure you want to approve the shift cancellation request?',
    APPROVE_GUARANTEED_SHIFT_CANCELLATION: 'This shift is guaranteed. The agency and staff will still get paid for it, despite cancelling. Are you sure you want to approve the shift cancellation request?',
    DENY_SHIFT_CANCELLATION: 'Are you sure you want to deny the shift cancellation request?',
};

export const SHIFT_ACTIONS = {
    CANCEL_SHIFT: 'CANCEL_SHIFT',
    CANCEL_GUARANTEED_SHIFT: 'CANCEL_GUARANTEED_SHIFT',
    REQUEST_SHIFT_CANCELLATION: 'REQUEST_SHIFT_CANCELLATION',
    APPROVE_SHIFT_CANCELLATION: 'APPROVE_SHIFT_CANCELLATION',
    APPROVE_GUARANTEED_SHIFT_CANCELLATION: 'APPROVE_GUARANTEED_SHIFT_CANCELLATION',
    DENY_SHIFT_CANCELLATION: 'DENY_SHIFT_CANCELLATION',
};

export const TIME_ENTRY_ACTIONS = {
    APPROVE_TIME_ENTRY: 'APPROVE_TIME_ENTRY',
    DENY_TIME_ENTRY: 'DENY_TIME_ENTRY',
}

export const TIME_ENTRY_STATUS = {
    REQUIRES_REVIEW: 'requires_review',
    APPROVED: 'approved',
    DENIED: 'denied',
    UNRESOLVED: 'unresolved'
}

export const FACILITY_OWNERS = {
    INTERNAL_FACILITIES: 'internal',
    EXTERNAL_FACILITIES: 'external',
};

export const DATE_TO_USE = [
  {
    name: 'Shift Date',
    id: 'shift_date',
  },
  {
    name: 'Approval Date',
    id: 'approval_date',
  }
]

export const TIME_RANGE = [
    {
        name: 'Clear Filter',
        id: 'clear',
    },
    {
        name: 'Today',
        id: '0',
    },
    {
        name: 'Week',
        id: '+7',
    },
    {
        name: 'Month',
        id: '+30',
    },
    {
        name: 'Past Week',
        id: '-7',
    },
    {
        name: 'Past Two Weeks',
        id: '-14',
    },
    {
        name: 'Past Month',
        id: '-30',
    },
    {
        name: 'Custom',
        id: 'custom',
    },
]

export const STATE_LIST = [
    {
        label: 'Alabama',
        value: 'AL',
    },
    {
        label: 'Alaska',
        value: 'AK',
    },
    {
        label: 'American Samoa',
        value: 'AS',
    },
    {
        label: 'Arizona',
        value: 'AZ',
    },
    {
        label: 'Arkansas',
        value: 'AR',
    },
    {
        label: 'California',
        value: 'CA',
    },
    {
        label: 'Colorado',
        value: 'CO',
    },
    {
        label: 'Connecticut',
        value: 'CT',
    },
    {
        label: 'Delaware',
        value: 'DE',
    },
    {
        label: 'District Of Columbia',
        value: 'DC',
    },
    {
        label: 'Federated States Of Micronesia',
        value: 'FM',
    },
    {
        label: 'Florida',
        value: 'FL',
    },
    {
        label: 'Georgia',
        value: 'GA',
    },
    {
        label: 'Guam',
        value: 'GU',
    },
    {
        label: 'Hawaii',
        value: 'HI',
    },
    {
        label: 'Idaho',
        value: 'ID',
    },
    {
        label: 'Illinois',
        value: 'IL',
    },
    {
        label: 'Indiana',
        value: 'IN',
    },
    {
        label: 'Iowa',
        value: 'IA',
    },
    {
        label: 'Kansas',
        value: 'KS',
    },
    {
        label: 'Kentucky',
        value: 'KY',
    },
    {
        label: 'Louisiana',
        value: 'LA',
    },
    {
        label: 'Maine',
        value: 'ME',
    },
    {
        label: 'Marshall Islands',
        value: 'MH',
    },
    {
        label: 'Maryland',
        value: 'MD',
    },
    {
        label: 'Massachusetts',
        value: 'MA',
    },
    {
        label: 'Michigan',
        value: 'MI',
    },
    {
        label: 'Minnesota',
        value: 'MN',
    },
    {
        label: 'Mississippi',
        value: 'MS',
    },
    {
        label: 'Missouri',
        value: 'MO',
    },
    {
        label: 'Montana',
        value: 'MT',
    },
    {
        label: 'Nebraska',
        value: 'NE',
    },
    {
        label: 'Nevada',
        value: 'NV',
    },
    {
        label: 'New Hampshire',
        value: 'NH',
    },
    {
        label: 'New Jersey',
        value: 'NJ',
    },
    {
        label: 'New Mexico',
        value: 'NM',
    },
    {
        label: 'New York',
        value: 'NY',
    },
    {
        label: 'North Carolina',
        value: 'NC',
    },
    {
        label: 'North Dakota',
        value: 'ND',
    },
    {
        label: 'Northern Mariana Islands',
        value: 'MP',
    },
    {
        label: 'Ohio',
        value: 'OH',
    },
    {
        label: 'Oklahoma',
        value: 'OK',
    },
    {
        label: 'Oregon',
        value: 'OR',
    },
    {
        label: 'Palau',
        value: 'PW',
    },
    {
        label: 'Pennsylvania',
        value: 'PA',
    },
    {
        label: 'Puerto Rico',
        value: 'PR',
    },
    {
        label: 'Rhode Island',
        value: 'RI',
    },
    {
        label: 'South Carolina',
        value: 'SC',
    },
    {
        label: 'South Dakota',
        value: 'SD',
    },
    {
        label: 'Tennessee',
        value: 'TN',
    },
    {
        label: 'Texas',
        value: 'TX',
    },
    {
        label: 'Utah',
        value: 'UT',
    },
    {
        label: 'Vermont',
        value: 'VT',
    },
    {
        label: 'Virgin Islands',
        value: 'VI',
    },
    {
        label: 'Virginia',
        value: 'VA',
    },
    {
        label: 'Washington',
        value: 'WA',
    },
    {
        label: 'West Virginia',
        value: 'WV',
    },
    {
        label: 'Wisconsin',
        value: 'WI',
    },
    {
        label: 'Wyoming',
        value: 'WY',
    },
];
