import {
    GET_SHIFT,
    SET_SHIFT_FIELD_VALUES,
    RESET_SHIFT_FORM,
    UPDATE_SHIFT,
    ADD_SHIFT,
    SET_SHIFT_FIELDS_VALUES,
    CLEAR_SHIFT_DATA,
    UPDATE_AGENCIES_BY_FACILITY_ID,
    UPDATE_AGENCIES,
    CLOCK_INTO_SHIFT,
} from './constants';

import { ACTION_TYPE } from '../../utils/Constants';

const initialData = {
    isFetching: false,
    agencies: [],
    time_entries: [],
    shift: {
        id: '',
        facility: {
            id: '',
        },
        shift_date: '',
        start_hours: '',
        start_minutes: '',
        end_hours: '',
        end_minutes: '',
        agencies: [],
        credentials: [],
        shift_histories: [],
        specialty: [],
        floor_units: {
            id: '',
        },
        licenses: [],
        notified: false,
        agency_accepted: {},
        status: '',
        guaranteed: false,
        time_and_a_half: false,
        bonus: false,
        note: '',
        notes: [],
        contract_id: '',
        contract: {
         id: '',
         shifts: [], 
        },
    },
};

export default function shiftReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_SHIFT.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_SHIFT.success:
            return {
                ...state,
                isFetching: false,
                shift: payload,
            };
        case GET_SHIFT.failure:
            return {
                ...state,
                isFetching: false,
                shift: null,
            };
        case SET_SHIFT_FIELD_VALUES: {
            return {
                ...state,
                shift: {
                    ...state.shift,
                    [payload.name]: payload.value,
                },
            };
        }
        case SET_SHIFT_FIELDS_VALUES:
            return {
                ...state,
                shift: {
                    ...state.shift,
                    ...payload,
                },
            };
        case UPDATE_AGENCIES_BY_FACILITY_ID:
            const agencies = state.agencies
                && state.agencies.filter(agency => agency.facilities && agency.facilities.some(facility => facility.id === payload));
            return {
                ...state,
                shift: {
                    ...state.shift,
                    agencies,
                },
            };
        case UPDATE_AGENCIES:
            if (payload.action === ACTION_TYPE.UPDATE) {
                return {
                    ...state,
                    agencies: payload.data,
                };
            }
            return {
                ...state,
                agencies: payload.data,
                shift: {
                    ...state.shift,
                    agencies: payload.data,
                },
            };
        case UPDATE_SHIFT.request:
            return {
                ...state,
                isFetching: true,
            };
        case UPDATE_SHIFT.success:
            return {
                ...state,
                isFetching: false,
            };
        case UPDATE_SHIFT.failure:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_SHIFT.request:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_SHIFT.success:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_SHIFT.failure:
            return {
                ...state,
                isFetching: false,
            };
        case CLOCK_INTO_SHIFT.request:
            return {
                ...state,
                isFetching: true,
            };
        case CLOCK_INTO_SHIFT.success:
            return {
                ...state,
                isFetching: false,
            };
        case CLOCK_INTO_SHIFT.failure:
            return {
                ...state,
                isFetching: false,
            };
        case RESET_SHIFT_FORM:
            return {
                isFetching: false,
                shift: {
                    ...initialData.shift,
                    facility: state.shift.facility,
                },
            };
        case CLEAR_SHIFT_DATA:
            return {
                agencies: [],
                isFetching: false,
                shift: initialData.shift,
            };
        default:
            return state;
    }
}
