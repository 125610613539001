import {
    GET_FACILITY_ADMIN,
    SET_FIELD_VALUES,
    RESET_FORM,
    UPDATE_FACILITY_ADMIN,
    ADD_FACILITY_ADMIN,
    GET_FACILITY_USERS,
    ASSOCIATE_FACILITY_ADMIN,
} from './constants';

const initialData = {
    isFetching: false,
    isFetchingList: false,
    usersList: [],
    user: {
        title: '',
        user_name: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        extension: '',
        email: '',
        notification: {
            cancellation_of_shift: true,
            cancellation_requests: true,
            claimed_shift: true,
            modification_of_shift: true,
            new_shifts: true,
        },
    },
};

export default function facilityAdminReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_FACILITY_ADMIN.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_FACILITY_ADMIN.success:
            return {
                ...state,
                isFetching: false,
                user: payload,
            };
        case GET_FACILITY_ADMIN.failure:
            return {
                ...state,
                isFetching: false,
                user: null,
            };
        case SET_FIELD_VALUES: {
            return {
                ...state,
                user: {
                    ...state.user,
                    [payload.name]: payload.value,
                },
            };
        }
        case ASSOCIATE_FACILITY_ADMIN.request:
            return {
                ...state,
                isFetching: true,
            };
        case ASSOCIATE_FACILITY_ADMIN.success:
            return {
                ...state,
                isFetching: false,
            };
        case ASSOCIATE_FACILITY_ADMIN.failure:
            return {
                ...state,
                isFetching: false,
            };
        case UPDATE_FACILITY_ADMIN.request:
            return {
                ...state,
                isFetching: true,
            };
        case UPDATE_FACILITY_ADMIN.success:
            return {
                ...state,
                isFetching: false,
            };
        case UPDATE_FACILITY_ADMIN.failure:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_FACILITY_ADMIN.request:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_FACILITY_ADMIN.success:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_FACILITY_ADMIN.failure:
            return {
                ...state,
                isFetching: false,
            };
        case GET_FACILITY_USERS.request:
            return {
                ...state,
                isFetchingList: true,
            };
        case GET_FACILITY_USERS.success:
            return {
                ...state,
                isFetchingList: false,
                usersList: payload,
            };
        case GET_FACILITY_USERS.failure:
            return {
                ...state,
                isFetchingList: false,
                usersList: null,
            };
        case RESET_FORM: {
            return initialData;
        }
        default:
            return state;
    }
}
