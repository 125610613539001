import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#414042',
            light: '#757575',
            dark: '#231f20',
            contrastText: '#fff',
        },
        secondary: {
            main: '#939598',
            contrastText: '#fff',
        },
        common: {
            black: '#000',
            white: '#fff',
        },
        info: {
            main: '#67c9d3',
            light: '#8dd4de',
            dark: '#24747D',
            highlight: '#0096FF',
            contrastText: '#fff',
        },
        success: {
            main: '#85bb65',
            light: '#80C883',
            dark: '#306e33',
            contrastText: '#fff',
        },
        warning: {
            main: "#b8b185",
            dark: "#aa9e68",
            contrastText: "#fff",
        },
        error: {
            main: "#f44336",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
    typography: {
        fontWeightLight: 300,
        fontWeightMedium: 600,
        fontWeightRegular: 400, 
        fontWeightBold: 700,
        h1: {
            fontFamily: [
                "Montserrat",
                "sans-serif"
            ].join(",")
        },
        h2: {
            fontFamily: [
                "Montserrat",
                "sans-serif"
            ].join(",")
        },
        h3: {
            fontFamily: [
                "Montserrat",
                "sans-serif"
            ].join(",")
        },
        h4: {
            fontFamily: [
                "Montserrat",
                "sans-serif"
            ].join(",")
        },
        fontFamily: [
            'Open Sans',
            'sans-serif'
        ].join(','),
        body1: {
            fontSize: 12.5,
        },
        body2: {
            fontSize: 12.5,
        },
    },
});

// Define sitewide classes
theme.overrides.MuiCssBaseline = {
    '@global': {
        body: {
            '& .warning-color': {
                color: theme.palette.warning.main,
           },
           '& .success-color': {
                color: theme.palette.success.dark,
           },
           '& .error-color': {
                color: theme.palette.error.main,
           },
           '& .info-color': {
               color: theme.palette.info.main,
           },
           '& a': {
               color: theme.palette.primary.main,
               fontWeight: theme.typography.fontWeightMedium,
           },
           '& a:hover': {
                color: theme.palette.warning.dark,
                textDecoration: "none",
           },
        }
    }   
};

export default theme;