import { apiActionTypes } from '../../middleware';
const base = '@@Containers/Settings/';

export const GET_FACILITY_TYPE_LIST = apiActionTypes(`${base}GET_FACILITY_TYPE_LIST`);
export const UPDATE_FACILITY_TYPE_LIST = apiActionTypes(`${base}UPDATE_FACILITY_TYPE_LIST`);
export const CREATE_FACILITY_TYPE_LIST = apiActionTypes(`${base}CREATE_FACILITY_TYPE_LIST`);

export const GET_CREDENTIALS_LIST = apiActionTypes(`${base}GET_CREDENTIALS_LIST`);
export const UPDATE_CREDENTIALS_LIST = apiActionTypes(`${base}UPDATE_CREDENTIALS_LIST`);
export const CREATE_CREDENTIALS_LIST = apiActionTypes(`${base}CREATE_CREDENTIALS_LIST`);

export const GET_SPECIALITY_LIST = apiActionTypes(`${base}GET_SPECIALITY_LIST`);
export const UPDATE_SPECIALITY_LIST = apiActionTypes(`${base}UPDATE_SPECIALITY_LIST`);
export const CREATE_SPECIALITY_LIST = apiActionTypes(`${base}CREATE_SPECIALITY_LIST`);

export const GET_LICENSE_LIST = apiActionTypes(`${base}GET_LICENSE_LIST`);
export const UPDATE_LICENSE_LIST = apiActionTypes(`${base}UPDATE_LICENSE_LIST`);
export const CREATE_LICENSE_LIST = apiActionTypes(`${base}CREATE_LICENSE_LIST`);

export const UPDATE_PRESET_LIST = apiActionTypes(`${base}UPDATE_PRESET_LIST`);
export const CREATE_PRESET_LIST = apiActionTypes(`${base}CREATE_PRESET_LIST`);

export const TYPE = {
    FACILITY_TYPE: 'FACILITY_TYPE',
    CREDENTIALS: 'CREDENTIALS',
    SPECIALITY: 'SPECIALITY',
    LICENSE: 'LICENSE',
    PRESET: 'PRESET',
};
