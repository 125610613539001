import {
    GET_AGENCY,
    SET_AGENCY_FIELD_VALUES,
    RESET_AGENCY_FORM,
    UPDATE_AGENCY,
    ADD_AGENCY,
} from './constants';

const initialData = {
    isFetching: false,
    agency: {
        id: '',
        name: '',
        phone: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zip: '',
        contact_first_name: '',
        contact_last_name: '',
        contact_email_address: '',
        contact_phone: '',
        contact_phone_ext: '',
        facilities: [],
        users: [],
    },
};

export default function agencyReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_AGENCY.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_AGENCY.success:
            return {
                ...state,
                isFetching: false,
                agency: payload,
            };
        case GET_AGENCY.failure:
            return {
                ...state,
                isFetching: false,
                agency: null,
            };
        case SET_AGENCY_FIELD_VALUES: {
            return {
                ...state,
                agency: {
                    ...state.agency,
                    [payload.name]: payload.value,
                },
            };
        }
        case UPDATE_AGENCY.request:
            return {
                ...state,
                isFetching: true,
            };
        case UPDATE_AGENCY.success:
            return {
                ...state,
                isFetching: false,
            };
        case UPDATE_AGENCY.failure:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_AGENCY.request:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_AGENCY.success:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_AGENCY.failure:
            return {
                ...state,
                isFetching: false,
            };
        case RESET_AGENCY_FORM: {
            return initialData;
        }
        default:
            return state;
    }
}
