import { apiActionTypes } from '../../middleware';
const base = '@@Containers/FacilityDetails/';

export const UPLOAD_DOCUMENT = apiActionTypes(`${base}UPLOAD_DOCUMENT`);
export const DOWNLOAD_DOCUMENT = apiActionTypes(`${base}DOWNLOAD_DOCUMENT`);
export const DELETE_DOCUMENT = apiActionTypes(`${base}DELETE_DOCUMENT`);
export const REVERIFY_FACILITY_ADMIN = apiActionTypes(`${base}REVERIFY_FACILITY_ADMIN`);
export const CREATE_FACILITY_FLOOR = apiActionTypes(`${base}CREATE_FACILITY_FLOOR`); 
export const UPDATE_FACILITY_FLOOR = apiActionTypes(`${base}UPDATE_FACILITY_FLOOR`); 
export const GET_FACILITY_FLOORS = apiActionTypes(`${base}GET_FACILITY_FLOORS`); 
