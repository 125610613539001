import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Amplify from 'aws-amplify';
import { Cache } from 'aws-amplify';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme.js';
import Routes from './routes';
import Loader from './components/Shared/Loader';
import { ENDPOINT } from './middleware/constants';
import GetSession from './middleware/session';
import * as actions from './containers/App/actions';
import { callApi } from './middleware/utils';
import CssBaseline from "@material-ui/core/CssBaseline";

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_USER_POOL_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID, // OPTIONAL - Amazon Cognito User Pool ID
        userPoolWebClientId: process.env.REACT_APP_USER_CLIENT_ID, // OPTIONAL - Amazon Cognito Web Client ID
        identityPoolId: process.env.REACT_APP_USER_IDENTITY_PROVIDER_ID,
    },
    API: {
        endpoints: [
            {
                name: ENDPOINT,
                endpoint: process.env.REACT_APP_API_ENDPOINT,
            },
        ],
    },
    Storage: {
      bucket: process.env.REACT_APP_AWS_BUCKET,
      region: process.env.REACT_APP_USER_POOL_REGION,
      customPrefix: {
        public: '',
      },
    }
});

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            isAuthenticating: true,
        };
    }

    async componentWillMount() {
        let currentUser;
        try {
            if (await GetSession()) {
                this.userHasAuthenticated(true);
                try {
                    // const currentUserInfoTest = await Auth.currentUserInfo();
                    // TODO: Change the user management to add username in the header.
                    currentUser = await callApi({ path: '/user/me', method: 'GET', config: { response: true } });
                    if (currentUser && currentUser.active === true) {
                      this.props.updateUser(currentUser);
                    } else {
                      toast.error('Login unsuccessful', { className: 'toast-error' });
                      Amplify.Auth.signOut().then(() => {
                          this.userHasAuthenticated(false);
                          Cache.clear();
                          this.props.history.push('/login');
                      }).catch((error) => console.log('error in sign out', error));
                    }
                } catch (e) {
                    toast.error('Login unsuccessful', { className: 'toast-error' });
                    this.userHasAuthenticated(false); 
                }
            }
        } catch (e) {
            if (e !== 'No current user') {
                // TODO: Handle this error
                console.log(e);
            }
        }
        this.setState({ isAuthenticating: false });
    }

    userHasAuthenticated = isAuthenticated => {
        this.setState({ isAuthenticated });
    };

    render() {
        const { isAuthenticated, isAuthenticating } = this.state;
        const childProps = {
            isAuthenticated,
            isAuthenticating,
            userHasAuthenticated: this.userHasAuthenticated,
        };
        return (
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <CssBaseline />
                    <div className="container container-fluid">
                        {!isAuthenticating && <Routes childProps={childProps} />}
                        <ToastContainer
                            position="top-right"
                            type="default"
                            autoClose={5000}
                            className="toast"
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            pauseOnHover
                        />
                        <Loader timedOut={isAuthenticating} />
                    </div>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        );
    }
}


App.propTypes = {
    updateUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            ...actions,
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(App);


