import {
    GET_FACILITY_TYPE_LIST,
    GET_CREDENTIALS_LIST,
    GET_LICENSE_LIST,
    GET_SPECIALITY_LIST,
    UPDATE_FACILITY_TYPE_LIST,
    UPDATE_CREDENTIALS_LIST,
    UPDATE_LICENSE_LIST,
    UPDATE_SPECIALITY_LIST,
    UPDATE_PRESET_LIST,
    CREATE_FACILITY_TYPE_LIST,
    CREATE_CREDENTIALS_LIST,
    CREATE_LICENSE_LIST,
    CREATE_SPECIALITY_LIST,
    CREATE_PRESET_LIST,
} from './constants';

const initialData = {
    isFetchingFacilityType: false,
    facilityTypeList: [],
    isFetchingCredentials: false,
    credentialsList: [],
    isFetchingLicense: false,
    licenseList: [],
    isFetchingSpeciality: false,
    specialityList: [],
    isFetchingPresets: false,
};

export default function settingsReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_FACILITY_TYPE_LIST.request:
        case UPDATE_FACILITY_TYPE_LIST.request:
        case CREATE_FACILITY_TYPE_LIST.request:
            return {
                ...state,
                isFetchingFacilityType: true,
            };
        case GET_FACILITY_TYPE_LIST.success:
            return {
                ...state,
                isFetchingFacilityType: false,
                facilityTypeList: payload,
            };
        case GET_FACILITY_TYPE_LIST.failure:
            return {
                ...state,
                isFetchingFacilityType: false,
                facilityTypeList: null,
            };
        case UPDATE_FACILITY_TYPE_LIST.success:
        case UPDATE_FACILITY_TYPE_LIST.failure:
        case CREATE_FACILITY_TYPE_LIST.success:
        case CREATE_FACILITY_TYPE_LIST.failure:
            return {
                ...state,
                isFetchingFacilityType: false,
            };
        case GET_CREDENTIALS_LIST.request:
        case UPDATE_CREDENTIALS_LIST.request:
        case CREATE_CREDENTIALS_LIST.request:
            return {
                ...state,
                isFetchingCredentials: true,
            };
        case GET_CREDENTIALS_LIST.success:
            return {
                ...state,
                isFetchingCredentials: false,
                credentialsList: payload,
            };
        case GET_CREDENTIALS_LIST.failure:
            return {
                ...state,
                isFetchingCredentials: false,
                credentialsList: null,
            };
        case UPDATE_CREDENTIALS_LIST.success:
        case UPDATE_CREDENTIALS_LIST.failure:
        case CREATE_CREDENTIALS_LIST.success:
        case CREATE_CREDENTIALS_LIST.failure:
            return {
                ...state,
                isFetchingCredentials: false,
            };
        case GET_LICENSE_LIST.request:
        case UPDATE_LICENSE_LIST.request:
        case CREATE_LICENSE_LIST.request:
            return {
                ...state,
                isFetchingLicense: true,
            };
        case GET_LICENSE_LIST.success:
            return {
                ...state,
                isFetchingLicense: false,
                licenseList: payload,
            };
        case GET_LICENSE_LIST.failure:
            return {
                ...state,
                isFetchingLicense: false,
                licenseList: null,
            };
        case UPDATE_LICENSE_LIST.success:
        case UPDATE_LICENSE_LIST.failure:
        case CREATE_LICENSE_LIST.success:
        case CREATE_LICENSE_LIST.failure:
            return {
                ...state,
                isFetchingLicense: false,
            };
        case GET_SPECIALITY_LIST.request:
        case UPDATE_SPECIALITY_LIST.request:
        case CREATE_SPECIALITY_LIST.request:
            return {
                ...state,
                isFetchingSpeciality: true,
            };
        case GET_SPECIALITY_LIST.success:
            return {
                ...state,
                isFetchingSpeciality: false,
                specialityList: payload,
            };
        case GET_SPECIALITY_LIST.failure:
            return {
                ...state,
                isFetchingSpeciality: false,
                specialityList: null,
            };
        case UPDATE_SPECIALITY_LIST.success:
        case UPDATE_SPECIALITY_LIST.failure:
        case CREATE_SPECIALITY_LIST.success:
        case CREATE_SPECIALITY_LIST.failure:
            return {
                ...state,
                isFetchingSpeciality: false,
            };
        case UPDATE_PRESET_LIST.request:
        case CREATE_PRESET_LIST.request:
            return {
                ...state,
                isFetchingPresets: true,
            };
        case UPDATE_PRESET_LIST.success:
        case UPDATE_PRESET_LIST.failure:
        case CREATE_PRESET_LIST.success:
        case CREATE_PRESET_LIST.failure:
            return {
                ...state,
                isFetchingPresets: false,
            };
        default:
            return state;
    }
}
