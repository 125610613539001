import { API } from 'aws-amplify';
import { CALL_API, ENDPOINT } from './constants';
import GetSession from './session';
import { getMethod } from './getMethod';
import { getTimeZone } from '../utils/getTimeZone';

export const apiActionTypes = x => ({
    request: `${x}_REQUEST`,
    success: `${x}_SUCCESS`,
    failure: `${x}_FAILURE`,
});

export const actionWith = (requestConfig, data) => {
    const finalAction = Object.assign({}, requestConfig, data);
    delete finalAction[CALL_API];
    return finalAction;
};

export const callApi = async requestConfig => {
    try {
        const session = await GetSession();
        const headers = Object.assign({}, requestConfig.headers, { jwtToken: session.idToken.jwtToken, timezone: getTimeZone() });
        const config = Object.assign({}, requestConfig.config, { headers });
        return API[getMethod(requestConfig.method)](ENDPOINT, requestConfig.path, config).then(response => {
            if (response.status >= 200 && response.status <= 204) {
                if (response.data) {
                    if (response.data.error) {
                        return Promise.reject(response.data.error);
                    }
                    return Promise.resolve(response.data);
                }
                // TODO: Decide what to return when response = "204 No Content"
                return null;
            }
            return Promise.reject(new Error('Unknown API Error'));
        }).catch(error => {
            if (error && error.response && error.response.data) {
                return Promise.reject(error.response.data);
            }
            return Promise.reject(error);
        });
    } catch (error) {
        return Promise.reject(error);
    }
};
