import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import appReducer from './containers/App/reducer';
import loginReducer from './containers/Login/reducer';
import forgotPasswordReducer from './containers/ForgotPassword/reducer';
import resetPasswordReducer from './containers/ResetPassword/reducer';
import agencyListReducer from './containers/AgencyList/reducer';
import agencyReducer from './containers/AgencyManagement/reducer';
import agencyDetailsReducer from './containers/AgencyDetails/reducer';
import agencyAdminReducer from './containers/AgencyAdminManagement/reducer';
import agencyUserReducer from './containers/AgencyUserManagement/reducer';
import facilityUserReducer from './containers/FacilityUserManagement/reducer';
import facilityListReducer from './containers/FacilityList/reducer';
import facilityReducer from './containers/FacilityManagement/reducer';
import facilityAdminReducer from './containers/FacilityAdminManagement/reducer';
import facilityDetailsReducer from './containers/FacilityDetails/reducer';
import staffListReducer from './containers/StaffList/reducer';
import timeEntryListReducer from './containers/TimeEntryList/reducer';
import timeEntryReducer from './containers/TimeEntryManagement/reducer';
import staffReducer from './containers/StaffManagement/reducer';
import staffDetailsReducer from './containers/StaffDetails/reducer';
import documentReducer from './containers/StaffDocumentManagement/reducer';
import settingsReducer from './containers/Settings/reducer';
import shiftListReducer from './containers/ShiftList/reducer';
import shiftReducer from './containers/ShiftManagement/reducer';

export default combineReducers({
    app: appReducer,
    routing: routerReducer,
    login: loginReducer,
    forgotPassword: forgotPasswordReducer,
    resetPassword: resetPasswordReducer,
    agencyList: agencyListReducer,
    agency: agencyReducer,
    agencyAdmin: agencyAdminReducer,
    agencyUser: agencyUserReducer,
    facilityUser: facilityUserReducer,
    agencyDetails: agencyDetailsReducer,
    settings: settingsReducer,
    facility: facilityReducer,
    facilityAdmin: facilityAdminReducer,
    facilityList: facilityListReducer,
    facilityDetails: facilityDetailsReducer,
    staffList: staffListReducer,
    staff: staffReducer,
    staffDetails: staffDetailsReducer,
    document: documentReducer,
    shiftList: shiftListReducer,
    shift: shiftReducer,
    timeEntries: timeEntryListReducer,
    timeEntry: timeEntryReducer
});
