import {
    GET_STAFF,
    SET_STAFF_FIELD_VALUES,
    RESET_STAFF_FORM,
    UPDATE_STAFF,
    ADD_STAFF,
} from './constants';

const initialData = {
    isFetching: false,
    staff: {
        id: '',
        first_name: '',
        last_name: '',
        user_name: '',
        email: '',
        phone_number: '',
        extension: '',
        home_phone: '',
        home_phone_ext: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zip: '',
        facilities: [],
        licenses: [],
        specialty: [],
        floor_units: [],
        credentials: [],
        notification: {
            cancellation_of_shift: true,
            cancellation_requests: true,
            claimed_shift: true,
            modification_of_shift: true,
            new_shifts: true,
        },
    },
};

export default function staffReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_STAFF.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_STAFF.success:
            return {
                ...state,
                isFetching: false,
                staff: payload,
            };
        case GET_STAFF.failure:
            return {
                ...state,
                isFetching: false,
                staff: null,
            };
        case SET_STAFF_FIELD_VALUES: {
            return {
                ...state,
                staff: {
                    ...state.staff,
                    [payload.name]: payload.value,
                },
            };
        }
        case UPDATE_STAFF.request:
            return {
                ...state,
                isFetching: true,
            };
        case UPDATE_STAFF.success:
            return {
                ...state,
                isFetching: false,
            };
        case UPDATE_STAFF.failure:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_STAFF.request:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_STAFF.success:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_STAFF.failure:
            return {
                ...state,
                isFetching: false,
            };
        case RESET_STAFF_FORM: {
            return initialData;
        }
        default:
            return state;
    }
}
