import {
    UPLOAD_DOCUMENT,
    DOWNLOAD_DOCUMENT,
    DELETE_DOCUMENT,
    REVERIFY_FACILITY_ADMIN,
    CREATE_FACILITY_FLOOR,
    UPDATE_FACILITY_FLOOR,
    GET_FACILITY_FLOORS,
} from './constants';

const initialData = {
    isFetching: false,
    floorsList: [],
};

export default function facilityDetailsReducer(state = initialData, {payload, ...action }) {
    switch (action.type) {
        case UPLOAD_DOCUMENT.request:
        case DOWNLOAD_DOCUMENT.request:
            return {
                ...state,
                isFetching: true,
            };
        case DELETE_DOCUMENT.request:
        case UPLOAD_DOCUMENT.success:
        case UPLOAD_DOCUMENT.failure:
        case DELETE_DOCUMENT.success:
        case DELETE_DOCUMENT.failure:
        case DOWNLOAD_DOCUMENT.success:
        case DOWNLOAD_DOCUMENT.failure:
            return {
                ...state,
                isFetching: false,
            };
        case REVERIFY_FACILITY_ADMIN.request:
            return {
                ...state,
                isFetching: true,
            };
        case REVERIFY_FACILITY_ADMIN.success:
            return {
                ...state,
                isFetching: false,
            };
        case REVERIFY_FACILITY_ADMIN.failure:
            return {
                ...state,
                isFetching: false,
            };
        case CREATE_FACILITY_FLOOR.request:
        case CREATE_FACILITY_FLOOR.success:
        case CREATE_FACILITY_FLOOR.failure:
        case UPDATE_FACILITY_FLOOR.request:
        case UPDATE_FACILITY_FLOOR.success:
        case UPDATE_FACILITY_FLOOR.failure:
        case GET_FACILITY_FLOORS.request:
        case GET_FACILITY_FLOORS.success:
            return {
                ...state,
                floorsList: payload,
            };
        case GET_FACILITY_FLOORS.failure:
            return {
                ...state,
                floorsList: null,
            };
        default:
            return state;
    }
}
