import {
    UPLOAD_DOCUMENT,
    DOWNLOAD_DOCUMENT,
    DELETE_DOCUMENT,
    REVERIFY_STAFF,
} from './constants';

const initialData = {
    isFetching: false,
};

export default function staffDetailsReducer(state = initialData, action) {
    switch (action.type) {
        case UPLOAD_DOCUMENT.request:
        case DELETE_DOCUMENT.request:
        case DOWNLOAD_DOCUMENT.request:
            return {
                ...state,
                isFetching: true,
            };
        case UPLOAD_DOCUMENT.success:
        case DOWNLOAD_DOCUMENT.success:
        case DELETE_DOCUMENT.success:
        case UPLOAD_DOCUMENT.failure:
        case DELETE_DOCUMENT.failure:
        case DOWNLOAD_DOCUMENT.failure:
            return {
                ...state,
                isFetching: false,
            };
        case REVERIFY_STAFF.request:
            return {
                ...state,
                isFetching: true,
            };
        case REVERIFY_STAFF.success:
            return {
                ...state,
                isFetching: false,
            };
        case REVERIFY_STAFF.failure:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
}
