import { apiActionTypes } from '../../middleware';
const base = '@@Containers/ShiftList/';

export const GET_SHIFT_LIST = apiActionTypes(`${base}GET_SHIFT_LIST`);
export const GET_SHIFT_BY_ID = apiActionTypes(`${base}GET_SHIFT_BY_ID`);
export const GET_STAFF_SHIFTS = apiActionTypes(`${base}GET_STAFF_SHIFTS`);
export const GET_ELIGIBLE_STAFF_LIST = apiActionTypes(`${base}GET_ELIGIBLE_STAFF_LIST`)
export const GET_STAFF_HOURS_FOR_WEEK = apiActionTypes(`${base}GET_STAFF_HOURS_FOR_WEEK`)
export const PAGINATED_GET_SHIFT_LIST = apiActionTypes(`${base}PAGINATED_GET_SHIFT_LIST`);
export const DELETE_SHIFT = apiActionTypes(`${base}DELETE_SHIFT`);
export const CLAIM_SHIFTS = apiActionTypes(`${base}CLAIM_SHIFTS`);
export const AGENCY_CLAIM_SHIFTS = apiActionTypes(`${base}AGENCY_CLAIM_SHIFTS`);
export const CLAIM_CONTRACT = apiActionTypes(`${base}CLAIM_CONTRACT`);
export const AGENCY_CLAIM_CONTRACT = apiActionTypes(`${base}AGENCY_CLAIM_CONTRACT`);
export const REQUEST_SHIFT_CANCELLATION = apiActionTypes(`${base}REQUEST_SHIFT_CANCELLATION`);
export const APPROVE_SHIFT_CANCELLATION_REQUEST = apiActionTypes(`${base}APPROVE_SHIFT_CANCELLATION_REQUEST`);
export const DENY_SHIFT_CANCELLATION_REQUEST = apiActionTypes(`${base}DENY_SHIFT_CANCELLATION_REQUEST`);
export const RESET_SHIFT_DATA = `${base}RESET_SHIFT_DATA`;

