import {
    GET_TIME_ENTRY_LIST,
    PAGINATED_GET_TIME_ENTRY_LIST,
} from './constants';

const initialData = {
    isFetching: false,
    isFetchingPaginatedResults: false,
    timeEntries: null,
    csvUrl: null,
    totalHours: null,
    entriesRequireReview: null,
};

export default function timeEntriesReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_TIME_ENTRY_LIST.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_TIME_ENTRY_LIST.success:
            return {
                ...state,
                isFetching: false,
                timeEntries: payload.data && payload.data.length > 0 ? payload.data : null,
                csvUrl: payload.url && payload.url.length > 0 ? payload.url : null,
                totalHours: payload.total_hours,
                entriesRequireReview: payload.entriesRequireReview,
            };
        case GET_TIME_ENTRY_LIST.failure:
            return {
                ...state,
                isFetching: false,
                timeEntries: null,
            };
        case PAGINATED_GET_TIME_ENTRY_LIST.request:
            return {
                ...state,
                isFetchingPaginatedResults: true,
            };
        case PAGINATED_GET_TIME_ENTRY_LIST.success:
            return {
                ...state,
                isFetchingPaginatedResults: false,
                timeEntries: payload.data && payload.data.length > 0 ? state.timeEntries.concat(payload.data) : state.timeEntries,
                totalHours: payload.total_hours,
                csvUrl: payload.url && payload.url.length > 0 ? payload.url : null,
            };
        case PAGINATED_GET_TIME_ENTRY_LIST.failure:
            return {
                ...state,
                isFetchingPaginatedResults: false,
            };
        default:
            return state;
    }
}
