import {
    GET_STAFF_LIST,
    PAGINATED_GET_STAFF_LIST,
    GET_STAFF_WITH_EXPIRING_DOCUMENTS,
    PAGINATED_GET_STAFF_WITH_EXPIRING_DOCUMENTS
} from './constants';

const initialData = {
    isFetching: false,
    isFetchingPaginatedResults: false,
    staffList: null,
};

export default function staffListReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_STAFF_LIST.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_STAFF_LIST.success:
            return {
                ...state,
                isFetching: false,
                staffList: payload.data,
            };
        case GET_STAFF_LIST.failure:
            return {
                ...state,
                isFetching: false,
                staffList: null,
            };
        case GET_STAFF_WITH_EXPIRING_DOCUMENTS.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_STAFF_WITH_EXPIRING_DOCUMENTS.success:
            return {
                ...state,
                isFetching: false,
                staffList: payload.data,
            };
        case GET_STAFF_WITH_EXPIRING_DOCUMENTS.failure:
            return {
                ...state,
                isFetching: false,
                staffList: null,
            };
        case PAGINATED_GET_STAFF_LIST.request:
            return {
                ...state,
                isFetchingPaginatedResults: true,
            };
        case PAGINATED_GET_STAFF_LIST.success:
            return {
                ...state,
                isFetchingPaginatedResults: false,
                staffList: payload.data && payload.data.length > 0 ? state.staffList.concat(payload.data) : state.staffList,
            };
        case PAGINATED_GET_STAFF_LIST.failure:
            return {
                ...state,
                isFetchingPaginatedResults: false,
            };
        case PAGINATED_GET_STAFF_WITH_EXPIRING_DOCUMENTS.request:
            return {
                ...state,
                isFetchingPaginatedResults: true,
            };
        case PAGINATED_GET_STAFF_WITH_EXPIRING_DOCUMENTS.success:
            return {
                ...state,
                isFetchingPaginatedResults: false,
                staffList: payload.data && payload.data.length > 0 ? state.staffList.concat(payload.data) : state.staffList,
            };
        case PAGINATED_GET_STAFF_WITH_EXPIRING_DOCUMENTS.failure:
            return {
                ...state,
                isFetchingPaginatedResults: false,
            };
        default:
            return state;
    }
}
