import {
    GET_SHIFT_LIST,
    GET_SHIFT_BY_ID,
    GET_ELIGIBLE_STAFF_LIST,
    GET_STAFF_HOURS_FOR_WEEK,
    DELETE_SHIFT,
    REQUEST_SHIFT_CANCELLATION,
    APPROVE_SHIFT_CANCELLATION_REQUEST,
    DENY_SHIFT_CANCELLATION_REQUEST,
    CLAIM_SHIFTS,
    AGENCY_CLAIM_SHIFTS,
    CLAIM_CONTRACT,
    AGENCY_CLAIM_CONTRACT,
    PAGINATED_GET_SHIFT_LIST,
    RESET_SHIFT_DATA,
    GET_STAFF_SHIFTS,
} from './constants';

const initialData = {
    isFetching: false,
    isFetchingStaff: false,
    isFetchingPaginatedResults: false,
    shiftList: null,
    staffList: null,
    staffHours: null,
    recentContract: null,
    numCancellationRequests: 0,
};

export default function shiftListReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case DELETE_SHIFT.request:
        case REQUEST_SHIFT_CANCELLATION.request:
        case APPROVE_SHIFT_CANCELLATION_REQUEST.request:
        case DENY_SHIFT_CANCELLATION_REQUEST.request:
        case CLAIM_SHIFTS.request:
        case AGENCY_CLAIM_SHIFTS.request:
        case GET_SHIFT_LIST.request:
        case GET_SHIFT_BY_ID.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_ELIGIBLE_STAFF_LIST.request:
            return {
                ...state,
                isFetchingStaff: true,
            };
        case GET_SHIFT_LIST.success:
            return {
                ...state,
                isFetching: false,
                shiftList: payload.data,
                recentContract: payload.recentContract,
                numCancellationRequests: payload.numCancellationRequests,
            };
        case GET_SHIFT_BY_ID.success:
            return {
                ...state,
                isFetching: false,
                shiftList: {
                    data: [payload],
                },
            };
        case GET_ELIGIBLE_STAFF_LIST.success:
            let staffList = state.staffList;

            if (staffList && payload) {
						  let payloadStaffIds = payload.map(p => p.Staff.id);

							if (payloadStaffIds.length > 0) {
  						  staffList = staffList.filter(s => payloadStaffIds.includes(s.Staff.id));
							} else {
							  staffList = [];
						  }
            } else {
              staffList = payload;
            }

            return {
                ...state,
                isFetchingStaff: false,
                staffList: staffList,
            };
        case GET_STAFF_HOURS_FOR_WEEK.success:
          return {
            ...state,
            isFetchingStaff: false,
            staffHours: payload.data,
          };
        case GET_SHIFT_LIST.failure:
        case GET_SHIFT_BY_ID.failure:
            return {
                ...state,
                isFetching: false,
                shiftList: null,
            };
        case DELETE_SHIFT.success:
        case REQUEST_SHIFT_CANCELLATION.success:
        case APPROVE_SHIFT_CANCELLATION_REQUEST.success:
        case DENY_SHIFT_CANCELLATION_REQUEST.success:
        case CLAIM_SHIFTS.success:
        case AGENCY_CLAIM_SHIFTS.success:
        case CLAIM_CONTRACT.success:
        case AGENCY_CLAIM_CONTRACT.success:
        case DELETE_SHIFT.failure:
        case REQUEST_SHIFT_CANCELLATION.failure:
        case APPROVE_SHIFT_CANCELLATION_REQUEST.failure:
        case DENY_SHIFT_CANCELLATION_REQUEST.failure:
        case CLAIM_SHIFTS.failure:
            return {
                ...state,
                isFetching: false,
            };
        case AGENCY_CLAIM_SHIFTS.failure:
            return {
                ...state,
                isFetching: false,
            };
        case CLAIM_CONTRACT.failure:
            return {
                ...state,
                isFetching: false,
            };
        case AGENCY_CLAIM_CONTRACT.failure:
            return {
                ...state,
                isFetching: false,
            };
        case PAGINATED_GET_SHIFT_LIST.request:
            return {
                ...state,
                isFetchingPaginatedResults: true,
            };
        case PAGINATED_GET_SHIFT_LIST.success:
            return {
                ...state,
                isFetchingPaginatedResults: false,
                shiftList: state.shiftList.concat(payload.data),
            };
        case PAGINATED_GET_SHIFT_LIST.failure:
            return {
                ...state,
                isFetchingPaginatedResults: false,
            };
        case GET_ELIGIBLE_STAFF_LIST.failure:
            return {
                ...state,
                staffList: null,
                isFetchingStaff: false,
            };
        case RESET_SHIFT_DATA:
            return {
                ...state,
                shiftList: null,
            };
        case GET_STAFF_SHIFTS.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_STAFF_SHIFTS.success:
            return {
                ...state,
                isFetching: false,
                shiftList: payload.data,
            };
        case GET_STAFF_SHIFTS.failure:
            return {
                ...state,
                isFetching: false,
                shiftList: null,
            };
        default:
            return state;
    }
}
