import { apiActionTypes } from '../../middleware';
const base = '@@Containers/FacilityAdminManagement/';

export const GET_FACILITY_ADMIN = apiActionTypes(`${base}GET_FACILITY_ADMIN`);
export const UPDATE_FACILITY_ADMIN = apiActionTypes(`${base}UPDATE_FACILITY_ADMIN`);
export const ASSOCIATE_FACILITY_ADMIN = apiActionTypes(`${base}ASSOCIATE_FACILITY_ADMIN`);
export const ADD_FACILITY_ADMIN = apiActionTypes(`${base}ADD_FACILITY_ADMIN`);
export const GET_FACILITY_USERS = apiActionTypes(`${base}GET_FACILITY_USERS`);
export const SET_FIELD_VALUES = `${base}SET_FIELD_VALUES`;
export const RESET_FORM = `${base}RESET_FORM`;
