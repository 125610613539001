import { apiActionTypes } from '../../middleware';
const base = '@@Containers/StaffDocumentManagement/';

export const GET_DOCUMENT = apiActionTypes(`${base}GET_DOCUMENT`);
export const UPDATE_DOCUMENT = apiActionTypes(`${base}UPDATE_DOCUMENT`);
export const ADD_DOCUMENT = apiActionTypes(`${base}ADD_DOCUMENT`);
export const SET_DOCUMENT_FIELD_VALUES = `${base}SET_DOCUMENT_FIELD_VALUES`;
export const SET_DOCUMENT_FIELDS_VALUES = `${base}SET_DOCUMENT_FIELDS_VALUES`;
export const RESET_DOCUMENT_FORM = `${base}RESET_DOCUMENT_FORM`;
export const CLEAR_DOCUMENT_DATA = `${base}CLEAR_DOCUMENT_DATA`;
export const UPLOAD_DOCUMENT = apiActionTypes(`${base}UPLOAD_DOCUMENT`);
export const DOWNLOAD_DOCUMENT = apiActionTypes(`${base}DOWNLOAD_DOCUMENT`);
export const DELETE_DOCUMENT = apiActionTypes(`${base}DELETE_DOCUMENT`);
