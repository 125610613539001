import { apiActionTypes } from '../../middleware';
const base = '@@Containers/ShiftManagement/';

export const GET_SHIFT = apiActionTypes(`${base}GET_SHIFT`);
export const UPDATE_SHIFT = apiActionTypes(`${base}UPDATE_SHIFT`);
export const ADD_SHIFT = apiActionTypes(`${base}ADD_SHIFT`);
export const SET_SHIFT_FIELD_VALUES = `${base}SET_SHIFT_FIELD_VALUES`;
export const SET_SHIFT_FIELDS_VALUES = `${base}SET_SHIFT_FIELDS_VALUES`;
export const UPDATE_AGENCIES_BY_FACILITY_ID = `${base}UPDATE_AGENCIES_BY_FACILITY_ID`;
export const UPDATE_AGENCIES = `${base}UPDATE_AGENCIES`;
export const RESET_SHIFT_FORM = `${base}RESET_SHIFT_FORM`;
export const CLEAR_SHIFT_DATA = `${base}CLEAR_SHIFT_DATA`;
export const CLOCK_INTO_SHIFT = apiActionTypes(`${base}CLOCK_INTO_SHIFT`);
export const CLOCK_OUT_OF_SHIFT = apiActionTypes(`${base}CLOCK_OUT_OF_SHIFT`);
