import {
    UPLOAD_DOCUMENT,
    DOWNLOAD_DOCUMENT,
    DELETE_DOCUMENT,
    REVERIFY_AGENCY_ADMIN,
} from './constants';

const initialData = {
    isFetching: false,
};

export default function agencyDetailsReducer(state = initialData, action) {
    switch (action.type) {
        case UPLOAD_DOCUMENT.request:
        case DOWNLOAD_DOCUMENT.request:
            return {
                ...state,
                isFetching: true,
            };
        case DELETE_DOCUMENT.request:
        case UPLOAD_DOCUMENT.success:
        case DOWNLOAD_DOCUMENT.success:
        case DELETE_DOCUMENT.success:
        case UPLOAD_DOCUMENT.failure:
        case DOWNLOAD_DOCUMENT.failure:
            return {
                ...state,
                isFetching: false,
            };
        case DELETE_DOCUMENT.failure:
        case REVERIFY_AGENCY_ADMIN.request:
            return {
                ...state,
                isFetching: true,
            };
        case REVERIFY_AGENCY_ADMIN.success:
            return {
                ...state,
                isFetching: false,
            };
        case REVERIFY_AGENCY_ADMIN.failure:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
}
